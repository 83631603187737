import * as React from 'react';

import styles from './container.module.css';

interface ContainerProps {
  children: React.ReactNode;
  narrow?: boolean;
}

export const Container = ({ children, narrow }: ContainerProps) => {
  const classNames = [styles.container, narrow && styles.narrow]
    .map((c) => c)
    .join(' ');
  return <div className={classNames}>{children}</div>;
};
