import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { footerLocationSlugs } from '../../../config/site';
import { FooterQuery } from '../../../graphql-types';
import { formatPhoneNumber } from '../../helpers/formatPhoneNumber';
import { Container } from '../Container';
import { Address } from '../Address';
import { SocialLinks } from '../SocialLinks';
import { CallMe } from '../CallMe';
import { getPathFromContentfulType } from '../../helpers/getPathFromContentfulType';

import styles from './footer.module.css';

/**
 * The location ids you want to show the details for in the footer.
 * Order matters.
 * Leave this as a blank array if you want to default to the site.js org config.
 */
const LOCATION_IDS = footerLocationSlugs;

const getNavLink = ({
  type,
  label,
  slug,
}: {
  type: string | null | undefined;
  label: string;
  slug: string;
}) => {
  if (type) {
    switch (type) {
      case 'location': {
        return (
          <Link to={getPathFromContentfulType.location(slug)}>{label}</Link>
        );
      }
      case 'page': {
        return <Link to={getPathFromContentfulType.page(slug)}>{label}</Link>;
      }
      case 'serviceArea': {
        return (
          <Link to={getPathFromContentfulType.serviceArea(slug)}>{label}</Link>
        );
      }
      default: {
        return label;
      }
    }
  }

  return label;
};

export const Footer = () => {
  const data = useStaticQuery<FooterQuery>(
    graphql`
      query Footer {
        site {
          siteMetadata {
            organization {
              name
              phoneNumber
              email
              legalName
              address {
                city
                secondaryAddress
                state
                streetAddress
                zipcode
              }
            }
          }
        }
        allContentfulLocation {
          edges {
            node {
              id
              slug
              name
              email
              phoneNumber
              address {
                streetAddress
                secondaryAddress
                city
                state
                zipcode
              }
            }
          }
        }

        contentfulNavigationList(displayLocation: { eq: "footer" }) {
          id
          displayLocation
          items {
            ... on ContentfulNavigationGroup {
              id
              label
              links {
                id
                label
                link {
                  slug
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const organization = data.site?.siteMetadata?.organization;

  const locations =
    LOCATION_IDS && LOCATION_IDS.length
      ? LOCATION_IDS.map((slug) =>
          data.allContentfulLocation.edges.find((l) => l.node.slug === slug)
        )
          .map((l) => l?.node)
          .filter((l) => l)
      : [];

  const navGroups = data.contentfulNavigationList?.items ?? [];

  const showContactInfo = navGroups.length < 5 && locations.length < 2;
  const showQuickForm = navGroups.length < 5 && locations.length > 1;

  return (
    <footer className={styles.footer}>
      <Container>
        <nav className={styles.top}>
          <ul className={[styles.nav, styles.row].join(' ')}>
            {navGroups.map((g) => {
              const links = g?.links ?? [];
              return (
                <li
                  key={g?.id}
                  className={[styles.navGroup, styles.col].join(' ')}
                >
                  <h5 className={styles.navGroupLabel}>{g?.label}</h5>
                  <ul className={styles.navGroupLinks}>
                    {links
                      .filter((l) => {
                        const type = l?.link?.sys?.contentType?.sys?.id ?? null;
                        return type !== null;
                      })
                      .map((l) => (
                        <li key={l?.id} className={styles.navGroupLink}>
                          {getNavLink({
                            type: l?.link?.sys?.contentType?.sys?.id,
                            label: l?.label as string,
                            slug: l?.link?.slug as string,
                          })}
                        </li>
                      ))}
                  </ul>
                </li>
              );
            })}
            {showContactInfo && (
              <li
                className={[styles.navGroup, styles.col, styles.col2].join(' ')}
              >
                {locations.map((l) => (
                  <ul
                    key={l?.id}
                    className={styles.contactinfo}
                    itemProp="location"
                    itemScope
                    itemType="http://schema.org/Place"
                  >
                    {l?.address ? (
                      <li>
                        <Address recipient={l?.name} address={l.address} />
                      </li>
                    ) : null}
                    {l?.email ? (
                      <li>
                        Email:{' '}
                        <a href={`mailto:${l.email}`}>
                          <span>{l.email}</span>
                        </a>
                      </li>
                    ) : null}
                    {l?.phoneNumber ? (
                      <li>
                        Tel:{' '}
                        <a href={`tel:${l.phoneNumber}`}>
                          <span itemProp="telephone">
                            {formatPhoneNumber(l.phoneNumber)}
                          </span>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                ))}
              </li>
            )}
            {showQuickForm && (
              <li
                className={[styles.navGroup, styles.col, styles.col2].join(' ')}
              >
                <h5 className={styles.navGroupLabel}>
                  Questions? We'll reach out.
                </h5>

                <p>Fill out your information below and we'll call you.</p>
                <CallMe
                  classNames={{
                    field: styles.quickContactField,
                    form: styles.quickContactForm,
                    button: styles.quickContactButton,
                  }}
                />
              </li>
            )}
          </ul>
        </nav>

        <div className={styles.bottom}>
          <div className={styles.social}>
            <SocialLinks />
          </div>
          <div className={styles.copyright}>
            Copyright © {new Date().getFullYear()}{' '}
            {organization?.legalName || organization?.name}
          </div>
        </div>
      </Container>
    </footer>
  );
};
