import * as React from 'react';

import styles from './input.module.css';

export const Input = ({
  className,
  ...props
}: React.ComponentProps<'input'>) => {
  const classNames = [styles.input, className].filter(c => c).join(' ');

  return <input className={classNames} {...props} />;
};
