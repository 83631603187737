export const formatPhoneNumber = (_phoneNumber: string): string => {
  const phoneNumber = _phoneNumber.toString().replace(/\D/g, '');

  const match = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
};
