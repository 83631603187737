import * as React from 'react';
import InputMask from 'react-input-mask';

import { Button } from '../Button';
import { Input } from '../Input';
import { initialFormState, formReducer } from './reducer';
import styles from './call-me.module.css';

/**
 * Our honeypot field is currently disguised as `lastName`.
 * We do not currently capture a `lastName` so it makes for a good honeypot field.
 * ie. Bots will fill out the honeypot field, and we can reject those submissions
 * because we know a bot filled them out. If we need to add lastName, we need to change
 * our honey pot field.
 */
interface Fields {
  firstName: string;
  lastName?: never; // This is our honeypot field! It should never be filled out!
  phoneNumber: string;
}

interface CallMeProps {
  children?: React.ReactNode;
  classNames?: {
    button?: string;
    form?: string;
    field?: string;
  };
}

const encode = (data: { [key: string]: string | undefined }) =>
  Object.keys(data)
    .map(key => {
      const value = data[key];

      return value
        ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        : null;
    })
    .filter(v => v)
    .join('&');

const FORM_NAME = 'Call Me';
const HONEYPOT_FIELD = 'lastName'; // Used for spam protection.

export const CallMe = ({ children, classNames }: CallMeProps) => {
  const [formState, dispatch] = React.useReducer(formReducer, initialFormState);

  const { status } = formState;

  const [fields, setFields] = React.useState<Fields>({
    firstName: '',
    phoneNumber: '',
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    setFields({ ...fields, [name]: value });
  };

  const onComplete = () => {
    setFields({ firstName: '', phoneNumber: '', [HONEYPOT_FIELD]: undefined });
    dispatch({ type: 'SUCCESS' });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // Prevent the default.
    e.preventDefault();

    // Set status to "loading".
    dispatch({ type: 'LOADING' });

    // Cleanup data.
    const data: Fields = {
      firstName: fields.firstName.trim(),
      phoneNumber: fields.phoneNumber.trim(),
      [HONEYPOT_FIELD]: fields[HONEYPOT_FIELD],
    };

    // Do work.
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': FORM_NAME, ...data }),
    })
      .then(res => {
        if (res.ok) {
          onComplete();
        } else {
          // Dispatch error.
          console.log(res);
        }
      })
      .catch(error => {
        // Dispatch error.
        console.log(error);
      });
  };

  const completed = status === 'success';
  const isLoading = status === 'loading';

  return (
    <div className={styles.container}>
      {completed ? (
        <div className={styles.message}>
          Thank you for submitting your information. We'll be in contact
          shortly!
        </div>
      ) : (
        <form
          className={[styles.form, classNames?.form].filter(c => c).join(' ')}
          onSubmit={onSubmit}
          name={FORM_NAME}
          data-netlify="true"
          data-netlify-honeypot={HONEYPOT_FIELD}
        >
          <div
            className={[styles.field, classNames?.field]
              .filter(c => c)
              .join(' ')}
          >
            <label className={styles.label} htmlFor="firstName">
              First name
            </label>
            <Input
              type="text"
              className={styles.input}
              name="firstName"
              id="firstName"
              placeholder="First name"
              aria-label="First name"
              autoComplete="given-name"
              value={fields.firstName}
              onChange={onInputChange}
              disabled={isLoading}
              required
              minLength={2}
            />
          </div>
          <div
            className={[styles.field, classNames?.field]
              .filter(c => c)
              .join(' ')}
          >
            <label className={styles.label} htmlFor="phoneNumber">
              Phone number
            </label>
            <InputMask
              type="tel"
              className={styles.input}
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Phone number"
              aria-label="Phone number"
              autoComplete="tel-national"
              value={fields.phoneNumber}
              onChange={onInputChange}
              disabled={isLoading}
              required
              minLength={10}
              pattern="\([0-9]{3}\) [0-9]{3}-[0-9]{4}"
              mask="(999) 999-9999"
            >
              {(inputProps: React.ComponentProps<'input'>) => (
                <Input {...inputProps} />
              )}
            </InputMask>
          </div>
          {/* Honeypot field! */}
          <div className={`${styles.field} hidden`}>
            <label
              className={styles.label}
              htmlFor={HONEYPOT_FIELD}
              aria-hidden="true"
            >
              Last name
            </label>
            <Input
              type="text"
              name={HONEYPOT_FIELD}
              id={HONEYPOT_FIELD}
              placeholder="Last name"
              aria-hidden="true"
              value={fields.lastName}
              onChange={onInputChange}
              minLength={2}
            />
          </div>
          {/* End Honeypot */}
          <div
            className={[styles.field, classNames?.button]
              .filter(c => c)
              .join(' ')}
          >
            <Button className="md-block" type="submit" disabled={isLoading}>
              {isLoading ? 'Submitting...' : 'Please call me in a few minutes'}
            </Button>
          </div>
          {children}
        </form>
      )}
    </div>
  );
};
