import * as React from 'react';

import style from './address.module.css';

interface AddressProps {
  address: {
    streetAddress?: string | null;
    secondaryAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zipcode?: string | null;
  };
  recipient?: string | null;
}

export const Address = ({ address, recipient }: AddressProps) => {
  const { streetAddress, secondaryAddress, city, state, zipcode } = address;

  if (streetAddress && city && state && zipcode) {
    const addressLine = (
      <>
        <span itemProp="streetAddress">{streetAddress}</span>
        {secondaryAddress ? `, ${secondaryAddress}` : null}
      </>
    );

    return (
      <address
        className={style.address}
        itemProp="address"
        itemScope
        itemType="https://schema.org/PostalAddress"
      >
        {recipient ? (
          <>
            <b itemProp="name">{recipient}</b>
            <br />
          </>
        ) : null}
        {addressLine}
        <br />
        <span itemProp="addressLocality">{city}</span>,{' '}
        <span itemProp="addressRegion">{state}</span>{' '}
        <span itemProp="postalCode">{zipcode}</span>
      </address>
    );
  }

  return <div>Address data is missing.</div>;
};
