/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';

import { SiteTitleQuery } from '../../../graphql-types';
import { Header } from '../Header';
import { Footer } from '../Footer';

import styles from './layout.module.css';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const data = useStaticQuery<SiteTitleQuery>(graphql`
    query SiteTitle {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://www.google-analytics.com" />
      </Helmet>
      <Header siteTitle={data.site?.siteMetadata?.title ?? undefined} />
      <main className={styles.main} role="main">
        {children}
      </main>
      <Footer />
    </>
  );
};
