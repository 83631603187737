import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import {
  ContentfulNavigationGroup,
  HeaderQuery,
  ContentfulNavigationItem,
} from '../../../graphql-types';
import { formatPhoneNumber } from '../../helpers/formatPhoneNumber';
import { Container } from '../Container';
import { ContentfulLink } from '../ContentfulLink';

import styles from './header.module.css';
import icon from './org-icon.svg';
import logo from './org-logo.svg';

interface HeaderProps {
  siteTitle?: string;
}

interface NavGroupProps {
  label: string;
  links: ContentfulNavigationGroup['links'];
}

interface NavItemProps {
  label: string;
  slug: string;
  type: string;
}

const NavGroup = ({ label, links: _links }: NavGroupProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const groupRef = React.useRef<HTMLLIElement>(null);
  const closeGroup = React.useCallback(
    (event: MouseEvent) => {
      if (groupRef && groupRef.current) {
        const isOutside = !groupRef.current.contains(event.target as any);

        console.log('isOutside', isOutside);
        if (isOutside) {
          setIsOpen(false);
        }
      }
    },
    [groupRef, setIsOpen]
  );

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', closeGroup);
    }

    return () => {
      document.removeEventListener('click', closeGroup);
    };
  }, [closeGroup, isOpen]);

  const links = _links ?? [];

  return (
    <li ref={groupRef}>
      <button
        className={[styles.navigationLabel, isOpen && 'is-open']
          .filter((c) => c)
          .join(' ')}
        onClick={() => setIsOpen(!isOpen)}
      >
        {label}
      </button>
      <ul>
        {links.map((l) => (
          <li key={l?.id}>
            <ContentfulLink
              slug={l?.link?.slug as string}
              type={l?.link?.sys?.contentType?.sys?.id as string}
            >
              {l?.label as string}
            </ContentfulLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

const NavItem = ({ label, slug, type }: NavItemProps) => (
  <li>
    <span className={styles.navigationLabel}>
      <ContentfulLink slug={slug} type={type}>
        {label}
      </ContentfulLink>
    </span>
  </li>
);

export const Header = ({ siteTitle = '' }: HeaderProps) => {
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      site {
        siteMetadata {
          organization {
            name
            phoneNumber
          }
        }
      }

      contentfulNavigationList(displayLocation: { eq: "header" }) {
        id
        displayLocation
        items {
          ... on ContentfulNavigationGroup {
            id
            label
            links {
              label
              link {
                slug
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                id
              }
              id
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulNavigationItem {
            id
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            label
            link {
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              id
              slug
            }
          }
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = React.useState(false);

  const org = data.site?.siteMetadata?.organization;
  const navGroups = data.contentfulNavigationList?.items ?? [];

  return (
    <header
      className={[styles.header, isOpen ? 'is-open' : null]
        .filter((s) => s)
        .join(' ')}
    >
      {org?.phoneNumber ? (
        <div className={styles.phoneNumberBanner}>
          <Container>
            Learn about our{' '}
            <Link to="/blog/covid-19-precautions-in-our-offices">
              COVID-19 office precautions
            </Link>{' '}
            or call us today at{' '}
            <a href={`tel:${org.phoneNumber}`}>
              {formatPhoneNumber(org.phoneNumber)}
            </a>
          </Container>
        </div>
      ) : null}
      <Container>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <Link to="/">
              {org ? (
                <img
                  className={styles.icon}
                  src={icon}
                  alt={[org.name ?? siteTitle, 'icon'].join(' ')}
                />
              ) : null}
              {org ? (
                <img
                  className={styles.logo}
                  src={logo}
                  alt={[org.name ?? siteTitle, 'logo'].join(' ')}
                />
              ) : null}
              <h1 className={styles.title}>{org?.name || siteTitle}</h1>
            </Link>
          </div>
          <nav
            className={styles.navigation}
            aria-label="Main navigation"
            id="main-navigation"
          >
            <ul>
              {navGroups.map((n) => {
                const type = n?.sys?.contentType?.sys?.id ?? null;
                if (type) {
                  if (type === 'navigationItem') {
                    const link = (n as Pick<
                      ContentfulNavigationItem,
                      'id' | 'label' | 'link'
                    >).link;
                    return (
                      <NavItem
                        key={n?.id}
                        label={n?.label as string}
                        slug={link?.slug as string}
                        type={link?.sys?.contentType?.sys?.id as string}
                      />
                    );
                  }

                  if (type === 'navigationGroup') {
                    return (
                      <NavGroup
                        key={n?.id}
                        label={n?.label as string}
                        links={(n as ContentfulNavigationGroup)?.links}
                      />
                    );
                  }
                }
              })}
            </ul>
          </nav>
          {org?.phoneNumber ? (
            <div className={styles.phoneNumber}>
              <a href={`tel:${org.phoneNumber}`}>
                {formatPhoneNumber(org.phoneNumber)}
              </a>
            </div>
          ) : null}
          <button
            className={styles.menuButton}
            type="button"
            aria-controls="main-navigation"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
            onClick={() => setIsOpen(!isOpen)}
          >
            {!isOpen ? (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                focusable="false"
              >
                <path
                  d="M4 16H28"
                  stroke="currentColor"
                  strokeWidth="2.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 8H28"
                  stroke="currentColor"
                  strokeWidth="2.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4 24H28"
                  stroke="currentColor"
                  strokeWidth="2.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                focusable="false"
              >
                <path
                  d="M25.3334 8.54675L23.4534 6.66675L16.0001 14.1201L8.54675 6.66675L6.66675 8.54675L14.1201 16.0001L6.66675 23.4534L8.54675 25.3334L16.0001 17.8801L23.4534 25.3334L25.3334 23.4534L17.8801 16.0001L25.3334 8.54675Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </button>
        </div>
      </Container>
    </header>
  );
};
