/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import { SiteSeoQuery } from '../../graphql-types';

interface SEOProps {
  data: SiteSeoQuery;
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements['meta'][];
  title: string;
}

function SEO({
  data,
  description = '',
  lang = 'en',
  meta = [],
  title,
}: SEOProps) {
  const { site } = data;
  const metaDescription = site?.siteMetadata?.description ?? description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s · ${site?.siteMetadata?.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site?.siteMetadata?.author ?? undefined,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  );
}

export default (props: Omit<SEOProps, 'data'>) => (
  <StaticQuery
    query={graphql`
      query SiteSEO {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={(data: SiteSeoQuery) => <SEO {...props} data={data} />}
  />
);
