// type ContentfulTypes = 'location' | 'page' | 'post' | 'review' | 'serviceArea';

// type GetContentfulPath = {
//   [key in ContentfulTypes]: (slug: string | never) => string;
// };

export const getPathFromContentfulType = {
  location: (slug: string) => `/locations/${slug}`,
  page: (slug: string) => {
    if (slug === 'home') return '/';

    return `/${slug}`;
  },
  post: (slug: string) => `/blog/${slug}`,
  review: () => `/reviews`,
  serviceArea: (slug: string) => `/service-areas/${slug}`,
};
