module.exports = {
  siteTitle: 'Modern Hearing Solutions · Hearing Aids in Cody and Powell WY', // Navigation and Site Title
  siteTitleAlt: '', // Alternative Site title for SEO
  siteTitleShort: 'modernhearingsolutions', // short_name for manifest
  siteUrl: process.env.URL || 'https://modernhearingsolutions.com', // Domain of site. No trailing slash!
  lang: 'en', // Language Tag on <html> element
  pathPrefix: '/',
  siteLogo: 'images/logo.png',
  siteDescription:
    'Modern Hearing Solutions provides hearing aids to help treat hearing loss in Cody and Powell Wyoming. We offer tinnitus treatment and hearing tests to help people with hearing problems. We provide unmatched service you can trust to hear what matters most.',
  author: 'Modern Hearing Solutions of Wyoming',

  // Manifest colors
  backgroundColor: '#663399',
  themeColor: '#663399',

  // Organization
  orgName: 'Modern Hearing Solutions of Wyoming',
  orgLegalName: 'Modern Hearing Solutions of Wyoming, LLC.',
  orgPhoneNumber: '+13072549633',
  orgEmail: 'MHSofWyoming@gmail.com',
  orgAddress: {
    streetAddress: '443 West Coulter Ave',
    secondaryAddress: 'Ste 6',
    city: 'Powell',
    state: 'WY', // Two letter
    zipcode: '82414',
  },
  orgGooglePlaceId: 'ChIJS-kj7tYYTFMROeoh5v7mUOU', // https://developers.google.com/maps/documentation/embed/start

  // Social
  twitter: '',
  facebook: 'https://www.facebook.com/MHSofWyoming',
  instagram: '',
  youtube: 'https://www.youtube.com/channel/UC_UJp6OidVohvoXNJUFn3xw',

  googleAnalytics: 'UA-121258376-1',

  // Slugs of locations to display in footer. Order preserved.
  footerLocationSlugs: ['powell', 'cody'],

  // Blog
  blogPageSize: 5,
};
