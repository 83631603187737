import * as React from 'react';

import styles from './button.module.css';

interface ExtendedButtonProps {
  block?: boolean;
}

export const Button = ({
  block,
  className,
  ...props
}: React.ComponentProps<'button'> & ExtendedButtonProps) => {
  const classNames = [styles.button, block && 'block', className]
    .filter(c => c)
    .join(' ');

  return <button className={classNames} {...props} />;
};
