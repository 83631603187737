import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { SocialLinksQuery } from '../../../graphql-types';
import styles from './social-links.module.css';

import Facebook from '../../images/social/facebook.inline.svg';
import Instagram from '../../images/social/instagram.inline.svg';
import Twitter from '../../images/social/twitter.inline.svg';
import YouTube from '../../images/social/youtube.inline.svg';

export const SocialLinks = () => {
  const data = useStaticQuery<SocialLinksQuery>(
    graphql`
      query SocialLinks {
        site {
          siteMetadata {
            social {
              facebook
              instagram
              twitter
              youtube
            }
          }
        }
      }
    `
  );

  const social = data.site?.siteMetadata?.social;

  return (
    <ul className={styles.social}>
      {social?.facebook ? (
        <li>
          <a
            href={social.facebook}
            target="_blank"
            rel="noopener"
            aria-label="Facebook"
          >
            <Facebook />
          </a>
        </li>
      ) : null}
      {social?.instagram ? (
        <li>
          <a
            href={social.instagram}
            target="_blank"
            rel="noopener"
            aria-label="Instagram"
          >
            <Instagram />
          </a>
        </li>
      ) : null}
      {social?.twitter ? (
        <li>
          <a
            href={social.twitter}
            target="_blank"
            rel="noopener"
            aria-label="Twitter"
          >
            <Twitter />
          </a>
        </li>
      ) : null}
      {social?.youtube ? (
        <li>
          <a
            href={social.youtube}
            target="_blank"
            rel="noopener"
            aria-label="YouTube"
          >
            <YouTube />
          </a>
        </li>
      ) : null}
    </ul>
  );
};
