import * as React from 'react';
import { Link } from 'gatsby';

import { getPathFromContentfulType } from '../../helpers/getPathFromContentfulType';

interface ContentfulLinkProps {
  children: string;
  slug: string;
  type: string | null | undefined;
}

export const ContentfulLink = ({
  children,
  slug,
  type,
}: ContentfulLinkProps) => {
  if (type != null) {
    let linkTo: string | null;

    switch (type) {
      case 'location': {
        linkTo = getPathFromContentfulType.location(slug);
        break;
      }
      case 'page': {
        linkTo = getPathFromContentfulType.page(slug);
        break;
      }
      case 'post': {
        linkTo = getPathFromContentfulType.post(slug);
        break;
      }
      case 'review': {
        linkTo = getPathFromContentfulType.review();
        break;
      }
      case 'serviceArea': {
        linkTo = getPathFromContentfulType.serviceArea(slug);
        break;
      }
      default: {
        linkTo = null;
        break;
      }
    }

    if (linkTo) {
      return <Link to={linkTo}>{children}</Link>;
    }
  }

  return <>{children}</>;
};
