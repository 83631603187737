type FormStatus = 'waiting' | 'loading' | 'success';

interface FormState {
  status: FormStatus;
}

interface LoadingAction {
  type: 'LOADING';
}

interface SuccessAction {
  type: 'SUCCESS';
}

type FormActions = LoadingAction | SuccessAction;

export const initialFormState: FormState = { status: 'waiting' };

export const formReducer = (
  state: FormState,
  action: FormActions
): FormState => {
  switch (action.type) {
    case 'LOADING': {
      return {
        ...state,
        status: 'loading',
      };
    }
    case 'SUCCESS': {
      return {
        ...state,
        status: 'success',
      };
    }
    default: {
      return state;
    }
  }
};
